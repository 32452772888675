import React from 'react'
import styled from 'styled-components'
import { Banner } from './Banner'
import DeliveryModelServiceBannerData from '../Data/DeliveryModelServiceBannerData'

const AppDeliveryModelServiceBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 10px;
    justify-content: space-between;
    background-color: #62a6c6c4;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 0px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .DeliveryModelBannerSectionOne {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5%;
    @media (max-width: 1390px) {
      margin: auto;
      order: 1;
    }
  }
  .DeliveryModelSectionTwo {
    width: 59%;
    height: 550px;
    @media (max-width: 1390px) {
      height: auto;
      width: auto;
      order: 0;
    }
  }
  .DeliveryModelBannerHeadingSize {
    display: none;
  }
  .DeliveryModelBannerPara {
    display: none;
  }
  .DeliveryModelServiceImage {
    width: 100%;
    height: auto;
  }
  .DeliveryModelBannerLiStyle {
    text-decoration: none;
    list-style-type: none;
    color: #fff;
    display: block;
    font-size: 1.2rem;
    word-spacing: normal;
    padding-bottom: 15%;
    max-width: 300px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  .DeliveryModelBannericonColor {
    color: #fff;
    padding: 15px;
  }
  .DeliveryModelBannerUlStyle {
    display: grid;
  }
  .IconDIS {
    display: none;
  }
`

export const AppDeliveryModelServiceBanner = () => {
  return (
    <AppDeliveryModelServiceBannerWapper>
      {DeliveryModelServiceBannerData.map((data, index) => {
        return (
          <div key={index} id={data.id}>
            <Banner
              containSection="containSection"
              ImagePath={data.ImagePath}
              LiIttemOne={data.LiPonitOne}
              LiIttemTwo={data.LiPonitTwo}
              LiIttemThree={data.LiPonitThree}
              LiIttemFour={data.LiPonitFour}
              Icon={data.icon}
              IconLast={data.icon}
              BannerSectionOne="DeliveryModelBannerSectionOne item-1"
              BannerSectionTwo="DeliveryModelSectionTwo"
              BannerHeadingSize="DeliveryModelBannerHeadingSize"
              BannerPara="DeliveryModelBannerPara"
              BannerImage="DeliveryModelServiceImage"
              altName="DeliveryModelServiceImage"
              LiStyle="DeliveryModelBannerLiStyle"
              IconOneStyle="DeliveryModelBannericonColor"
              IconTwoStyle="DeliveryModelBannericonColor"
              IconThreeStyle="DeliveryModelBannericonColor"
              IconFourStyle="DeliveryModelBannericonColor"
              IconFiveStyle="IconDIS"
              UlStyle="DeliveryModelBannerUlStyle"
              scrollPathOne="Delivery"
              scrollPathTwo="FrontEnd"
              scrollPathThree="OperationServices"
              scrollPathFour="BackEnd"
            />
          </div>
        )
      })}
    </AppDeliveryModelServiceBannerWapper>
  )
}
