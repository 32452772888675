import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import ExperienceServiceData from '../Data/ExperienceServiceData.json'

const AppServiceExperienceWapper = styled.div`
  .mainContain {
    max-width: 1920px;
    margin: auto;
  }
  .InternalSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  /* .containExperienceHeading {
    text-align: center;
    margin-top: 10%;
    padding-left: 40%;
    @media (max-width: 1090px) {
      margin-top: 0%;
      padding-left: 0%;
    }
  } */
  .containExperienceHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .containExperienceHeadingBig {
    padding: 0px 75px 3% 0px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .ExperiencePara {
    max-width: 975px;
    padding: 15px 75px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .ExperienceParaNone {
    display: none;
  }
  .ExperienceImage {
    height: auto;
    width: 53%;
    @media (max-width: 1090px) {
      margin-top: 0%;
      width: 100%;
    }
  }
  .ExperienceImageCenter {
    width: 100%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .paraSection {
    margin-top: auto;
    margin-bottom: auto;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px;
  }
  .buttonDisplay {
    display: none;
  }
`

export const AppServiceExperience = () => {
  return (
    <AppServiceExperienceWapper>
      {ExperienceServiceData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.para}
              ImagePath={data.imagePath}
              mainContain='mainContain'
              containHeading='containExperienceHeadingSmall'
              containHeadingBig='containExperienceHeadingBig'
              containParaOne='ExperiencePara'
              containParaTwo='ExperienceParaNone'
              containParaThree='ExperienceParaNone'
              containParaFour='ExperienceParaNone'
              containParaFive='ExperienceParaNone'
              InternalSection='InternalSection'
              containImage='ExperienceImage'
              altName={data.AltName}
              paraSection='paraSection'
              IconColor='iconColor'
              ButtonDisplay='buttonDisplay'
              allImage='ExperienceImageCenter'
            />
          </div>
        )
      })}
    </AppServiceExperienceWapper>
  )
}
