import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: 'Services',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/ServicesOurBanner.png',
    icon: <FaArrowCircleRight />,
    LiPonitOne: 'Service Delivery Model',
    LiPonitTwo: 'Front End Services',
    LiPonitThree: 'Operation Services',
    LiPonitFour: 'Back End Services',
  },
]
