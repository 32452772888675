import React from 'react'
import styled from 'styled-components'
import AlliancesServiceData from '../Data/AlliancesServiceData.json'
import { Block } from './Block'

const AppServiceAlliancesWpper = styled.div`
  .AlliancesContain {
    max-width: 1920px;
    margin: auto;
  }
  .AlliancesSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  /* .AlliancesHeading {
    text-align: left;
    margin-top: 10%;
    padding-left: 10%;
    @media (max-width: 1090px) {
      text-align: center;
      margin-top: 0%;
      padding-left: 0%;
    }
  } */
  .AlliancesHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .AlliancesHeadingBig {
    padding: 0px 0px 3% 0px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .AlliancesImage {
    height: auto;
    order: 1;
    @media (max-width: 1090px) {
      order: 0;

      margin-top: 0%;
    }
  }
  .AlliancesImageCenter {
    width: 100%;
  }
  .AlliancesParaSection {
    order: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1rem;
    @media (max-width: 1090px) {
      order: 1;
    }
  }
  .AlliancesPara {
    max-width: 775px;
    padding: 20px 0px 15px 0px;
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
    }
  }
  .AlliancesParaNone {
    display: none;
  }
  .buttonDisplay {
    display: none;
    justify-content: center;
    padding: 5%;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px;
  }
  .PonitPara {
    margin-top: -15%;
  }
`

export const AppServiceAlliances = () => {
  return (
    <AppServiceAlliancesWpper>
      {AlliancesServiceData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.para}
              ImagePath={data.imagePath}
              mainContain='AlliancesContain'
              containHeading='AlliancesHeadingSmall'
              containHeadingBig='AlliancesHeadingBig'
              containParaOne='AlliancesPara'
              containParaTwo='AlliancesParaNone'
              containParaThree='AlliancesParaNone'
              containParaFour='AlliancesParaNone'
              containParaFive='AlliancesParaNone'
              InternalSection='AlliancesSection'
              containImage='AlliancesImage'
              altName={data.AltName}
              paraSection='AlliancesParaSection'
              ButtonDisplay='buttonDisplay'
              allImage='AlliancesImageCenter'
            />
          </div>
        )
      })}
    </AppServiceAlliancesWpper>
  )
}
