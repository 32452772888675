import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: 'Delivery',
    heading: 'Service Delivery Model',
    icon: <FaArrowCircleRight />,
    ParaOne:
      'Our professional services delivers cost savings and cost avoidance to our customers by leveraging our extensive industry contacts, alliances, and commodity knowledge. While we manage, support, optimize, and manage the end-to-end Sourcing Management cycle. Most of our engagements are self-funded by delivering cost reduction on the addressable spend, freeing up admin hours needed to work across several sourcing tools and automating several sourcing operations.',
  },
]
