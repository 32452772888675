import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import EngagementsServiceData from '../Data/EngagementsServiceData.json'

const AppServiceEngagementsWapper = styled.div`
  .mainEngagementsContain {
    max-width: 1920px;
    margin: auto;
  }
  .InternalEngagementsSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  /* .containEngagementsHeading {
    text-align: center;
    margin-top: 10%;
    padding-left: 40%;
    @media (max-width: 1090px) {
      margin-top: 0%;
      padding-left: 0%;
    }
  } */
  .containEngagementsHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .containEngagementsHeadingBig {
    padding: 0px 65px 3% 95px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .EngagementsPara {
    max-width: 975px;
    padding: 15px 65px 15px 95px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .EngagementsParaNone {
    display: none;
  }
  .EngagementsImage {
    height: auto;
    @media (max-width: 1090px) {
      margin-top: 0%;
    }
  }
  .EngagementsImageCenter {
    width: 100%;
  }
  .paraEngagementsSection {
    margin-top: auto;
    margin-bottom: auto;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px;
  }
  .buttonDisplay {
    display: none;
  }
`

export const AppServiceEngagements = () => {
  return (
    <AppServiceEngagementsWapper>
      {EngagementsServiceData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.para}
              ImagePath={data.imagePath}
              mainContain='mainEngagementsContain'
              containHeading='containEngagementsHeadingSmall'
              containHeadingBig='containEngagementsHeadingBig'
              containParaOne='EngagementsPara'
              containParaTwo='EngagementsParaNone'
              containParaThree='EngagementsParaNone'
              containParaFour='EngagementsParaNone'
              containParaFive='EngagementsParaNone'
              InternalSection='InternalEngagementsSection'
              containImage='EngagementsImage'
              altName={data.AltName}
              paraSection='paraEngagementsSection'
              IconColor='iconColor'
              ButtonDisplay='buttonDisplay'
              allImage='EngagementsImageCenter'
            />
          </div>
        )
      })}
    </AppServiceEngagementsWapper>
  )
}
