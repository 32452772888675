import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'

const BlockCardWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
  }
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
  }
`

export const BlockCard = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <BlockCardWapper>
      <motion.div
        className={props.MainWapper}
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
        <div className={props.CardSection}>
          <div>
            <img src={props.ImagePath} className={props.imageSection} alt={props.AltName} />
          </div>
          <h2 className={props.HeadingSection}>{props.Heading}</h2>
          <p className={props.ParaSection}>{props.para}</p>
        </div>
      </motion.div>
    </BlockCardWapper>
  )
}
