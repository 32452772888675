import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: 'OperationServices',
    heading: 'Operation Services',
    icon: <FaArrowCircleRight />,
    ParaOne:
      'We provide best in class expertise with direct/indirect commodity knowledge to execute on the procurement strategy and attain value.',
    ParaTwo: 'Full cycle end to end commodity management across all categories of spend.',
    ParaThree: 'Deliver current market supplier intelligence and procurement analytics.',
    ParaFour: 'Procurement Services – Sourcing for various commodities, negotiations, contract creation.',
  },
]
