import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import DeliveryModelFrontEndData from '../Data/DeliveryModelFrontEndData'

const AppDeliveryModelFrontEndPonitWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .FrontEndContainer {
    padding: 3% 8% 3% 5%;
  }
  .FrontEndHeading {
    padding-bottom: 3%;
  }
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppDeliveryModelFrontEndPonit = () => {
  return (
    <AppDeliveryModelFrontEndPonitWapper>
      {DeliveryModelFrontEndData.map((data, index) => {
        return (
          <div key={index} className="FrontEndContainer" id={data.id}>
            <ReUseHTwotag Heading={data.heading} HeadingStyle="FrontEndHeading" />
            <ReUsePtag para={data.ParaOne} Icon={data.icon} IconStyle="IconColor" paraStyle="FrontEndPara" />
            <ReUsePtag para={data.ParaTwo} Icon={data.icon} IconStyle="IconColor" paraStyle="FrontEndPara" />
            <ReUsePtag para={data.ParaThree} Icon={data.icon} IconStyle="IconColor" paraStyle="FrontEndPara" />
            <ReUsePtag para={data.ParaFour} Icon={data.icon} IconStyle="IconColor" paraStyle="FrontEndPara" />
          </div>
        )
      })}
    </AppDeliveryModelFrontEndPonitWapper>
  )
}
