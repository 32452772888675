import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: 'FrontEnd',
    heading: 'Front End Services',
    icon: <FaArrowCircleRight />,
    ParaOne:
      'We institutionalize the Procurement function by working with the client to define the strategy and all aspects of people, process, and the right fit configuration of Raindrop Platform for value realization.',
    ParaTwo: 'Analysis of the organization’s current capability level and access areas for transformation.',
    ParaThree: 'Crafting the “to-be” Procurement model, with Raindrop platform and processes.',
    ParaFour:
      'Providing training and change management necessary to move the Procurement organization along the value realization path.',
  },
]
