import React from 'react'
import styled from 'styled-components'
import { BlockCard } from './BlockCard'
import ServiceCardData from '../Data/ServiceCardData.json'

const AppServiceViewCardWapper = styled.div`
  display: flex;
  margin: auto;
  max-width: 1920px;
  justify-content: space-between;
  @media (max-width: 1391px) {
    flex-flow: wrap;
    justify-content: center;
  }
  .ContainerSection {
    margin: auto;
    max-width: 1920px;
    display: flex;
    justify-content: space-between;
  }
  .cardSection {
    max-width: 550px;
    border-right: 2px solid #000;
    @media (max-width: 1391px) {
      max-width: 950px;
      border-right: none;
    }
  }
  .ImageStyle {
    width: 15%;
    margin: auto;
    display: block;
    /* width: 28%;
    margin-left: auto;
    padding-right: 3rem; */
  }
  .Titlestyle {
    text-align: center;
    margin-top: 5%;
    /* text-align: left;
    margin-top: 5%;
    padding-left: 2rem; */
  }
  .paraStyle {
    text-align: center;
    padding: 2%;
    /* padding: 2rem 2rem 2rem 2rem;
    text-align: left; */
  }
`

export const AppServiceViewCard = () => {
  return (
    <AppServiceViewCardWapper>
      {ServiceCardData.map((data, index) => {
        return (
          <div key={index} className='ViewCaedSection'>
            <BlockCard
              ImagePath={data.ImagePath}
              Heading={data.Title}
              para={data.para}
              AltName={data.AltName}
              MainWapper='ContainerSection'
              CardSection='cardSection'
              imageSection='ImageStyle'
              HeadingSection='Titlestyle'
              ParaSection='paraStyle'
            />
          </div>
        )
      })}
    </AppServiceViewCardWapper>
  )
}
