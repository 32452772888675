import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import DeliveryModelServiceData from '../Data/DeliveryModelServiceData'

const AppDeliveryModelServicePointWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SecviceContainer {
    padding: 3% 8% 3% 5%;
  }
  .ServiceHeading {
    padding-bottom: 3%;
  }
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppDeliveryModelServicePonit = () => {
  return (
    <AppDeliveryModelServicePointWapper>
      {DeliveryModelServiceData.map((data, index) => {
        return (
          <div key={index} className='SecviceContainer' id={data.id}>
            <ReUseHTwotag Heading={data.heading} HeadingStyle='ServiceHeading' />
            <ReUsePtag para={data.ParaOne} Icon={data.icon} IconStyle='IconColor' paraStyle='ServicePara' />
          </div>
        )
      })}
    </AppDeliveryModelServicePointWapper>
  )
}
