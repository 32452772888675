import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Heading: 'Professional Services Elevated',
    paraOne:
      "Raindrop's Professional Services team designs, transforms, and runs Procurement functions for global enterprises. We implement and configure the Raindrop platform to fit your organization's Sourcing and Procurement needs, and institutionalize the necessary processes. With deep experience across Indirect and Direct spend categories and supplier relationships, we ensure our customers have the spend visibility needed to be successful.",
    icon: <FaArrowCircleRight />,
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/ServicesPageBanner.png',
    AltName: "Organization's sourcing & procurement needs",
    LiPonitOne: 'Differentiators',
    LiPonitTwo: 'Service Delivery Model',
    LiPonitThree: 'Our Services',
  },
]
