import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: 'BackEnd',
    heading: 'Back End Services',
    icon: <FaArrowCircleRight />,
    ParaOne:
      'Business Process Fulfillment – Raindrop’s Services team provides around-the clock “Source to Contract” (S2C) business process fulfillment, including Purchase Requisition to Purchase Order (PR2PO), spot buying, Goods Receipt processing, Account Payable support, Procurement analytics and reporting, and contracts management.',
  },
]
