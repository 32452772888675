import React from 'react'
import styled from 'styled-components'
import { Banner } from './Banner'
import ServiceBannerData from '../Data/ServiceBannerData'

const AppServiceBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 96px 50px 96px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 80px 30px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .BannerServiceSectionOne {
  }
  .ServiceBannerSectionTwo {
    height: auto;
    width: 45%;
    @media (max-width: 1090px) {
      height: auto;
      width: auto;
    }
  }
  .BannerServicesHeadingSize {
    max-width: 700px;
    text-align: left;
    padding-bottom: 3%;
    font-size: 2.5rem;
    line-height: 1.30435em;
  }
  .BannerServicePara {
    max-width: 750px;
    padding: 10px 0px 0px 0px;
    @media (max-width: 1650px) {
      max-width: 700px;
    }
  }
  .BannerServiceImage {
    width: 100%;
    height: auto;
    margin-top: -2%;
    @media (max-width: 1650px) {
      width: 80%;
      display: flex;
      float: right;
    }
    @media (max-width: 1090px) {
      margin-top: 2rem;
      width: 100%;
      float: unset;
    }
    @media (max-width: 400px) {
      margin-top: 5rem;
    }
  }
  .BannerServiceLiStyle {
    text-decoration: none;
    list-style-type: none;
    padding: 1%;
    word-break: unset;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .BannerServiceUlStyle {
    display: grid;
    margin: -35px auto;
    padding-left: unset;
  }
  .iconColorDis {
    display: none;
  }
`

export const AppServiceBanner = () => {
  return (
    <AppServiceBannerWapper>
      {ServiceBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Banner
              containSection='containSection'
              BannerHeading={data.Heading}
              paraOne={data.paraOne}
              ImagePath={data.ImagePath}
              LiIttemOne={data.LiPonitOne}
              LiIttemTwo={data.LiPonitTwo}
              LiIttemThree={data.LiPonitThree}
              Icon={data.icon}
              BannerSectionOne='BannerServiceSectionOne item-1'
              BannerSectionTwo='ServiceBannerSectionTwo'
              BannerHeadingSize='BannerServicesHeadingSize'
              BannerPara='BannerServicePara'
              BannerImage='BannerServiceImage'
              altName={data.AltName}
              UlStyle='BannerServiceUlStyle'
              LiStyle='BannerServiceLiStyle'
              IconOneStyle='iconColor'
              IconTwoStyle='iconColor'
              IconThreeStyle='iconColor'
              IconFourStyle='iconColorDis'
              IconFiveStyle='iconColorDis'
              scrollPathOne='Differentiators'
              offsetValue={-250}
              scrollPathTwo='Delivery'
              scrollPathThree='Services'
            />
          </div>
        )
      })}
    </AppServiceBannerWapper>
  )
}
