import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import DeliveryModelBackEndData from '../Data/DeliveryModelBackEndData'

const AppDeliveryModelBackEndPonitWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .BackEndContainer {
    padding: 3% 8% 3% 5%;
  }
  .BackEndHeading {
    padding-bottom: 3%;
  }
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppDeliveryModelBackEndPonit = () => {
  return (
    <AppDeliveryModelBackEndPonitWapper>
      {DeliveryModelBackEndData.map((data, index) => {
        return (
          <div key={index} className='BackEndContainer' id={data.id}>
            <ReUseHTwotag Heading={data.heading} HeadingStyle='BackEndHeading' />
            <ReUsePtag para={data.ParaOne} Icon={data.icon} IconStyle='IconColor' paraStyle='BackEndPara' />
          </div>
        )
      })}
    </AppDeliveryModelBackEndPonitWapper>
  )
}
